import React, { useState, useEffect } from 'react';
import { Field, Formik } from 'formik';
import { Modal, ModalBody } from '@brighthr/component-modal';

import Button from '@brighthr/component-button';
import LinkButton from '@brighthr/component-linkbutton';
import Icon from '@brighthr/component-icon';
import * as Yup from 'yup';
import createAccount from 'services/account/create';
import verifyRegistrationDetails from 'services/verifyRegistrationDetails';
import getCountriesAndJurisdictions from 'services/getCountriesAndJurisdictions';
import config from 'utils/config';
import loadable from '@loadable/component';
import FormikSelect from 'components/FormikUI/FormikSelect';
import Spinner from '@brighthr/component-spinner';

const Layouts = {
	Default: loadable(() => import('../../../layouts/Default')),
	VaccTrak: loadable(() => import('../../../layouts/VaccTrak')),
	Lightning: loadable(() => import('../../../layouts/Lightning')),
	HRLite: loadable(() => import('../../../layouts/HRLite')),
	SafeLite: loadable(() => import('../../../layouts/SafeLite')),
};

const baseLightningPagesList = [
	'Bright Lightning',
	'Santander Lightning',
	'Thirtieth Ltd Lightning',
	'Myco Lightning',
	'Chambers Ireland Lightning',
	'IE - Chambers Ireland Lightning',
	'IE - County Tipperary Chamber Lightning',
	'IE - South Dublin Chamber Lightning',
	'CA - Lyoha Lightning',
	'Barrett Stacey Lightning',
	'IE - Letterkenny Chamber Lightning',
	'Vodafone Lightning',
	'Empact Ventures Lightning',
	'Lit Within Lightning',
];

const schema = Yup.object().shape({
	countryCode: Yup.string().label('Country').required(),
	jurisdictionCode: Yup.string().label('Region').required(),
	password: Yup.string().label('Password').max(50).required(),
	confirmpassword: Yup.string().label('Confirm password').max(50).required(),
});

const requirements = [
	{
		label: '1 uppercase letter',
		check: (values) => values.password?.match(/(?=.*[A-Z])/) !== null,
	},

	{
		label: 'Minimum 12 characters',
		check: (values) => values.password?.match(/[A-Za-z\d\W_]{12,}/) != null,
	},
	{
		label: '1 number/symbol',
		check: (values) => values.password?.match(/(?=.*[\d\W_])/) !== null,
	},
	{
		label: 'Passwords match',
		check: (values) =>
			!!values.password &&
			!!values.confirmpassword &&
			values.password === values.confirmpassword,
	},
];
const isDisabled = (values) => requirements.some(({ check }) => !check(values));

const { VACCTRAK_URL, LIGHTNING_URL, HR_URL, BRIGHTSAFE_URL } = config();

const getAppUrl = (origin) => {
	if (baseLightningPagesList.includes(origin)) return LIGHTNING_URL;
	if (origin === 'VaccTrak Lite') return VACCTRAK_URL;
	if (origin === 'BrightHR Lite') return HR_URL;
	if (origin === 'BrightSafe Lite') return BRIGHTSAFE_URL;
};

const getAppName = (origin) => {
	if (baseLightningPagesList.includes(origin)) return 'BrightHR Lightning';
	if (origin === 'BrightHR Lite') return 'BrightHR Lite';
	return origin;
};

export default ({
	match: {
		params: { guid },
	},
}) => {
	const [submitting, setSubmitting] = useState(false);
	const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
	const [registrationDetails, setRegistrationDetails] = useState({});
	const [countriesAndJurisdictions, setCountriesAndJurisdictions] = useState({
		countryCodes: [],
		jurisdictions: [],
	});
	const [registrationStatus, setRegistrationStatus] = useState('UNVERIFIED');
	const [error, setError] = useState(false);

	const [Layout, setLayout] = useState(Layouts.Default);

	useEffect(() => {
		Promise.all([verifyRegistrationDetails(guid), getCountriesAndJurisdictions()])
			.then(([_registrationDetails, _countriesAndJurisdictions]) => {
				setRegistrationStatus('VALID');
				setRegistrationDetails(_registrationDetails);
				setCountriesAndJurisdictions(_countriesAndJurisdictions);

				if (_registrationDetails.origin === 'VaccTrak Lite') {
					setLayout(Layouts.VaccTrak);
				}

				if (baseLightningPagesList.includes(_registrationDetails.origin)) {
					setLayout(Layouts.Lightning);
				}
				if (_registrationDetails.origin === 'BrightHR Lite') {
					setLayout(Layouts.HRLite);
				}
				if (_registrationDetails.origin === 'BrightSafe Lite') {
					setLayout(Layouts.SafeLite);
				}
			})
			.catch(() => setRegistrationStatus('NOT_VALID'));
	}, [guid]);

	if (registrationStatus === 'UNVERIFIED') return null;

	return (
		<Layout>
			<div className="max-w-screen-lg">
				{registrationStatus === 'NOT_VALID' && <div>Not found</div>}
				{(submitting || submissionSuccessful) && (
					<Modal width="sm">
						<ModalBody className="m-10 text-center" data-testid="Submission modal">
							{!submissionSuccessful && (
								<>
									<h1 className="mb-4 text-2xl font-bold text-primary-700">
										You’re almost there…
									</h1>
									<p className="mb-6">
										Your brand-new {getAppName(registrationDetails.origin)}{' '}
										account will be ready for you in a few moments.
									</p>
									<Spinner
										size="base"
										ariaLabel="loading content"
										className="mx-auto stroke-primary-700"
									/>
								</>
							)}
							{submissionSuccessful && (
								<>
									<h1 className="mb-4 text-2xl font-bold text-primary-700">
										You’re all done!
									</h1>
									<p className="mb-6">
										Your account has now been created. All that’s left for you
										to do is click the button below to start using{' '}
										{getAppName(registrationDetails.origin)}:
									</p>
									<div className="flex justify-center">
										<LinkButton
											text="Get started"
											href={getAppUrl(registrationDetails.origin)}
										/>
									</div>
								</>
							)}
						</ModalBody>
					</Modal>
				)}
				{registrationStatus === 'VALID' && (
					<>
						<h1 className="mb-12 text-2xl font-bold text-primary-700">
							Finish setting up your account
						</h1>
						<h2 className="mb-2 text-lg font-bold text-neutral-700">
							{registrationDetails.firstName} {registrationDetails.lastName}
						</h2>
						<p className="mb-5">{registrationDetails.email}</p>
						<Formik
							initialValues={{
								countryCode: 'GB',
								jurisdictionCode: 'ENGLANDANDWALES',
								password: '',
								confirmpassword: '',
								registrationId: guid,
							}}
							validateOnBlur
							validateOnChange={false}
							validationSchema={schema}
							onSubmit={({
								password,
								registrationId,
								countryCode,
								jurisdictionCode,
							}) => {
								setSubmitting(true);
								setError(false);

								return createAccount({
									password,
									registrationId,
									countryCode,
									jurisdictionCode,
								})
									.then(() => {
										setSubmissionSuccessful(true);
										setSubmitting(false);
									})
									.catch(() => {
										setError(true);
										setSubmitting(false);
									});
							}}
						>
							{(formik) => {
								const { countryCodes, jurisdictions } = countriesAndJurisdictions;
								const selectedCountryJurisdictions = jurisdictions.filter(
									({ countryCode }) => countryCode === formik.values.countryCode
								);

								useEffect(() => {
									if (
										selectedCountryJurisdictions &&
										selectedCountryJurisdictions.length > 0
									) {
										formik.setFieldValue(
											'jurisdictionCode',
											selectedCountryJurisdictions[0].code
										);
									}
								}, [formik.values.countryCode]); // eslint-disable-line react-hooks/exhaustive-deps

								return (
									<form onSubmit={formik.handleSubmit} className="space-y-6">
										<div>
											<label htmlFor="countryCode">
												<div className="">Country</div>
												<div>
													<FormikSelect
														id="countryCode"
														name="countryCode"
														value={formik.values.countryCode}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													>
														{countryCodes.map(
															({ code, description }) => (
																<option value={code} key={code}>
																	{description}
																</option>
															)
														)}
													</FormikSelect>
												</div>
											</label>
											{formik.touched.countryCode && (
												<div className="mt-2 text-error-500">
													{formik.errors.countryCode}
												</div>
											)}
										</div>
										{selectedCountryJurisdictions.length > 1 && (
											<div>
												<label htmlFor="jurisdictionCode">
													<div className="">Jurisdiction</div>
													<div>
														<FormikSelect
															id="jurisdictionCode"
															name="jurisdictionCode"
															value={formik.values.jurisdictionCode}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
														>
															{selectedCountryJurisdictions.map(
																({ code, description }) => (
																	<option value={code} key={code}>
																		{description}
																	</option>
																)
															)}
														</FormikSelect>
													</div>
												</label>
												{formik.touched.jurisdictionCode && (
													<div className="mt-2 text-error-500">
														{formik.errors.jurisdictionCode}
													</div>
												)}
											</div>
										)}
										<div>
											<div className="mb-4">
												<label htmlFor="password">
													<div className="">Password</div>
													<Field
														id="password"
														type="password"
														autoComplete="off"
														name="password"
														className="w-full px-3 py-2 m-0 mt-2 border-2 rounded border-neutral-500 "
													/>
												</label>
												{formik.touched.password && (
													<div className="mt-2 text-error-500">
														{formik.errors.password}
													</div>
												)}
											</div>
											<ul className="grid grid-cols-2 gap-4">
												{requirements.map((req) => {
													const valid = req.check(formik.values);

													return (
														<li
															className="flex items-center"
															key={req.label}
														>
															<div
																data-testid="validation"
																className="flex-none w-4 h-4 mr-3"
															>
																{valid ? (
																	<Icon
																		iconName="tick-thick"
																		size={20}
																		className="fill-success-600"
																		data-testid="Tick icon"
																	/>
																) : (
																	<Icon
																		iconName="cross-thick"
																		size={20}
																		className="fill-error-500"
																		data-testid="Cross icon"
																	/>
																)}
															</div>

															<div className="flex-1">
																{req.label}
															</div>
														</li>
													);
												})}
											</ul>
										</div>
										<div>
											<label htmlFor="confirmpassword">
												<div className="">Confirm password</div>
												<Field
													id="confirmpassword"
													type="password"
													autoComplete="off"
													name="confirmpassword"
													className="w-full px-3 py-2 m-0 mt-2 border-2 rounded border-neutral-500 "
												/>
											</label>
											{formik.touched.confirmpassword && (
												<div className="mt-2 text-error-500">
													{formik.errors.confirmpassword}
												</div>
											)}
										</div>

										<Button
											type="submit"
											loading={submitting}
											disabled={
												isDisabled(formik.values) ||
												submitting ||
												formik.values.password !==
													formik.values.confirmpassword
											}
											text="Complete signup"
										/>
										{error && <div>problem submitting form</div>}
									</form>
								);
							}}
						</Formik>
					</>
				)}
			</div>
		</Layout>
	);
};
