import http from 'utils/http';
import config from 'utils/config';

const claimInvite = (data, guid) => {
	// data should be { password: 'string' }
	const { AUTHORISATION_SERVER_URL } = config();
	const uri = `${AUTHORISATION_SERVER_URL}/service/account/invite/${guid}`;

	return http.post(uri, { data, withCredentials: true }).then((res) => res.data);
};

export default claimInvite;
